import { GetServerSidePropsContext } from 'next';
import React from 'react';

import PageSpinner from '../../components/atoms/page-spinner';
import { ErrorFallback } from '../../components/organisms/error-boundary';
import { NotFound } from '../../components/organisms/not-found';
import { FallbackPageTemplate } from '../../components/templates/fallback-page-template';
import { PageTemplate } from '../../components/templates/page-template';
import { ShopPageTemplate } from '../../components/templates/shop-page-template';
import { OrganisationObjectType, ShopPaginatedResultObjectType } from '../../generated/graphql';
import { MinimalServerError } from '../../types';
import { getOrganisation } from '../../utils/api';
import { serverRedirect } from '../../utils/redirect';
import { Sentry, captureException } from '../_app';

interface PageProps {
  organisation?: OrganisationObjectType;
  error?: MinimalServerError;
}

export default function Index(props: PageProps) {
  let { organisation, error } = props;

  if (error) {
    return (
      <FallbackPageTemplate>
        <ErrorFallback error={error} />
      </FallbackPageTemplate>
    );
  }

  if (!organisation) {
    return (
      <PageTemplate>
        <NotFound />
      </PageTemplate>
    );
  }

  return (
    <ShopPageTemplate organisation={organisation} shop={undefined} shoppingCart={[]} reservations={[]}>
      <PageSpinner />
    </ShopPageTemplate>
  );
}

const ShopsQuery = `
query ListShops {
  getOrganisationWoytiShops {
    docs {
      _id
      name
      address {
        street
        streetNumber
        zipcode
        city
        state
        country
      }
    }
  }
}
`;

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  try {
    let organisationData = await getOrganisation(ctx);

    if (!organisationData) {
      return {
        props: {},
      };
    } else {
      let result = await organisationData.graphqlClient.request<{
        getOrganisationWoytiShops: ShopPaginatedResultObjectType;
      }>(ShopsQuery, {});

      if (result.getOrganisationWoytiShops.docs.length) {
        let shopPath = [
          ctx.locale,
          organisationData.organisation.pathname,
          result.getOrganisationWoytiShops.docs[0]._id,
        ].join('/');

        serverRedirect(`/${shopPath}`, ctx);
      }

      return {
        props: {
          organisation: organisationData.organisation,
        },
      };
    }
  } catch (err) {
    const eventId = captureException(err);
    await Sentry.flush();

    return {
      props: {
        error: {
          message: err.message,
          eventId,
        },
      },
    };
  }
}
